import React from 'react';
import './Undergrad.css';
import img1 from '../../utils/images/arm8.jpg';

const Undergrad = () => {
    return (
        <>
            <div className='undergrad'>
                <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
                    <div className='container-md d-flex flex-column align-items-center'>
                        <div className='d-flex flex-column flex-sm-column align-items-center'>
                            <div className='container-md'>
                                <p>Welcome to Armelubawa College of Engineering: Igniting Your Engineering Odyssey</p>
                            </div>
                            {/* <Link to="/courses">
                                <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Our Courses</button>
                            </Link>
                            <Link to="/contact">
                                <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                            </Link> */}
                            <div>
                                <p>Embark on a transformative journey at Armelubawa College of Engineering, where academic excellence meets innovation. As you explore the exciting possibilities within our esteemed institution, discover the comprehensive admission requirements tailored to set you on the path to success.</p>
                            </div>
                        </div>
                    </div>
                </header>


                <div className="container-lg bg-light my-4 p-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 col-sm-10">
                            <div className='text-center my-5'>
                                <h2><span className='fw-semibold'>Admission Requirements:</span> Unveiling Your Potential</h2>
                            </div>
                            <p>At Armelubawa, we believe in nurturing a diverse and dynamic student body. Our admission requirements are designed to ensure that you not only meet the academic standards but also possess the passion and commitment to thrive in the field of engineering.</p>
                            <p>Field of Study: Choose your path among our distinguished programs in Electrical Engineering, Mechanical Engineering, Computer Science, and Civil Engineering.</p>
                            <p>English Proficiency Test: Showcase your language prowess through one of the internationally recognized tests. We accept IELTS (Minimum Score: 6.5), Duolingo English Test (Minimum Score: 90), and TOEFL (Minimum Score: 80 - Internet-based test).</p>
                            <p>SAT Exam Points: Tailored to each field of study, our minimum SAT exam point requirements are as follows:</p>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Department</th>
                                        <th scope="col">Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>Electrical Engineering</td>
                                        <td>1300</td>

                                    </tr>
                                    <tr>
                                        <th scope="row">2</th>
                                        <td>Mechanical Engineering</td>
                                        <td>1250</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">3</th>
                                        <td>Computer Science</td>
                                        <td>1350</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">4</th>
                                        <td>Civil Engineering</td>
                                        <td>1200</td>
                                    </tr>
                                    <tr>
                                        <th scope='row'>5</th>
                                        <td>Computer Engineering</td>
                                        <td>1200</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>Armelubawa Engineering Entry Exam: Seal your commitment to excellence by participating in the Armelubawa Engineering Entry Exam. Achieve a minimum score of 50% to be considered for admission.</p>
                        </div>

                    </div>
                </div>

                <div className="container-md my-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-10">
                            <div className="text-center">
                                <h3><span className='fw-semibold'>Application Process:</span> Crafting Your Future</h3>
                            </div>
                            <p>All applications must be submitted through our official admission email: admission@armelubawaengineering.org. Ensure your application includes the following documents:</p>
                            <div className="container col-md-6">
                                <ul class="list-group">
                                    <li class="list-group-item">Completed application form</li>
                                    <li class="list-group-item">Transcripts and certificates</li>
                                    <li class="list-group-item">Duolingo English Test score report</li>
                                    <li class="list-group-item">SAT exam score report</li>
                                    <li class="list-group-item">Statement of Interest (for those interested in free training)</li>
                                </ul>
                            </div>
                            <div className="container col-md-10 my-4">
                                <h5 className='my-4'>Fields of Study: Unleash Your Engineering Passion</h5>
                                <p>&#xF138;<span className='fw-semibold ms-1'>Electrical Engineering:</span> Dive into circuits, electronics, and power systems. Design and implement electrical solutions for a dynamic world.Click here for more </p>
                                <p>&#xF138;<span className='fw-semibold ms-1'>Mechanical Engineering:</span> Explore the mechanics of machines, materials, and energy. Master the art of designing and analyzing mechanical systems. Click here for more</p>
                                <p>&#xF138;<span className='fw-semibold ms-1'>Computer Science:</span> Immerse yourself in programming, algorithms, and software development. Unleash your creativity in the ever-evolving world of technology. Click here for more</p>
                                <p>&#xF138;<span className='fw-semibold ms-1'>Civil Engineering:</span> Shape the future of infrastructure with expertise in structural engineering, transportation, and environmental sustainability. Click here for more</p>
                            </div>

                        </div>
                    </div>

                    <div className="container-md bg-light p-5 my-5">
                        <div className="row align-items-center justify-content-center ">
                            <div className="col-md-6 border rounded p-4 my-4">
                                <div>
                                    <h5><span>Free Training Opportunities:</span><span className='ms-2 text-muted'>Elevate Your Skills</span></h5>
                                </div>
                                <p>For those eager to enhance their skills further, Armelubawa College of Engineering offers free SAT preparation and a specialized Engineering Entry Exam preparation course. Express your interest by sending a Statement of Interest to admission@armelubawaengineering.org.</p>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-6 border rounded p-4 my-4">
                                <div>
                                    <h5>English Proficiency Test Requirements: A Gateway to Global Success</h5>
                                </div>
                                <p>As a global engineering community, effective communication is paramount. Demonstrate your language proficiency with IELTS, Duolingo, or TOEFL. Choose the test that aligns with your strengths and aspirations, ensuring you are well-equipped for the challenges of the modern engineering landscape.</p>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-6 border rounded p-4 my-4">
                                <div>
                                    <h5>Submit Your Scores and Unleash Your Potential!</h5>
                                </div>
                                <p>Include your official test score report along with your application materials when applying to Armelubawa College of Engineering. Meeting the specified minimum scores ensures you possess the language skills to excel in our dynamic academic environment.</p>
                                <p>Join us at Armelubawa College of Engineering, where innovation, diversity, and academic excellence converge to shape the engineers of tomorrow. For any inquiries regarding the admission process, feel free to contact our admissions team at admission@armelubawaengineering.org. We eagerly await your application as you embark on your extraordinary journey in the world of engineering!   </p>
                            </div>
                        </div>
                    </div>
                    {/* about persons */}


                    <div className="container-md">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-10">
                                <div className="text-center">
                                </div>
                                <div>
                                    <div className="clearfix">
                                        <img src={img1} class="col-md-6 float-md-end mb-3 ms-md-3" alt="..." />
                                        <p>
                                            Dr. Anna Ernst serves as the Undergraduate Admission Officer at Armelubawa College of Engineering, where she plays a pivotal role in shaping the next generation of engineering leaders. With a background in electrical engineering and a Ph.D. in the same field, Anna brings a wealth of expertise and passion for engineering education to her role.
                                        </p>
                                        <h5>
                                            Professional Background
                                        </h5>

                                        <p>
                                            Anna earned her Bachelor of Science in Electrical Engineering from a prestigious institution, where she demonstrated exceptional aptitude and dedication to her field. Motivated by her passion for advancing knowledge and contributing to the engineering community, she pursued further education, obtaining her Ph.D. in Electrical Engineering.
                                        </p>

                                        <p>
                                            Throughout her academic journey, Anna distinguished herself through her research contributions, innovative problem-solving skills, and commitment to excellence. Her work has been published in several peer-reviewed journals, earning her recognition within the academic community.
                                        </p>
                                        <h5>
                                            Career Path
                                        </h5>
                                        <p>
                                            After completing her doctoral studies, Anna transitioned into the realm of engineering education and administration, driven by her desire to inspire and mentor aspiring engineers. As the Undergraduate Admission Officer at Armelubawa College of Engineering, she plays a crucial role in attracting top talent and guiding prospective students through the admissions process.
                                        </p>
                                        <p>
                                            Anna's deep understanding of electrical engineering, coupled with her firsthand experience as a graduate student, enables her to provide invaluable insights and guidance to prospective students as they navigate their educational journey. She is passionate about fostering a diverse and inclusive learning environment where all students can thrive and achieve their full potential.

                                        </p>

                                        <p>
                                            And yet, here you are, still persevering in reading this placeholder text, hoping for some more insights, or some hidden easter egg of content. A joke, perhaps. Unfortunately, there's none of that here.
                                        </p>
                                        <h5>
                                            Contact Anna Ernst
                                        </h5>
                                        <p>
                                            As the Undergraduate Admission Officer, Anna is dedicated to assisting prospective students with their admissions inquiries and guiding them through the application process. Whether you have questions about our programs, admissions requirements, or campus life, Anna is here to help.
                                        </p>
                                        <p>Email: <a href='mailto:anna.ernst@armelubawaengineering.org'>anna.ernst@armelubawaengineering.org</a></p>
                                        <p className='text-danger'>Join us at Armelubawa College of Engineering, where Anna and our dedicated admissions team are committed to helping you embark on a rewarding and transformative engineering education journey.</p>
                                        
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>





                </div>
            </div>
            </>
            )

}

export default Undergrad;