import React from "react";
import './History.css'
import img1 from '../../utils/images/arm4.png';

function History() {
    return (
        <>
            <div className="history">
                <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
                    <div className='container d-flex flex-column align-items-center'>
                        <div className='d-flex flex-column flex-sm-column align-items-center'>
                            <h1>The History of Armelubawa College of Engineering</h1>
                            <p className='fw-bold'>
                            </p>
                            {/* <Link to="/courses">
                                <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Our Courses</button>
                            </Link>
                            <Link to="/contact">
                                <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                            </Link> */}
                        </div>
                    </div>
                </header>
                <div className="container-md my-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-10">
                            <div className="text-center">
                            </div>
                            <div>
                                <div className="clearfix">
                                    <img src={img1} class="col-md-6 float-md-end mb-3 ms-md-3 shadow-lg" alt="..." />
                                    <p>
                                        In the storied year of 1981, amidst the technological fervor of the era, Armelubawa Hunbessen, a visionary electrical engineer brimming with passion and foresight, laid the cornerstone for what would soon emerge as a bastion of engineering brilliance – Armelubawa College of Engineering.
                                    </p>
                                    <h5>
                                        Inception and Founding Vision
                                    </h5>
                                    <p>
                                        Armelubawa Hunbessen, driven by a fervent desire to cultivate engineering excellence and foster innovation, established the college with a singular vision in mind: to create a premier institution that would serve as a crucible for the brightest minds in engineering. With unwavering determination and a profound commitment to academic excellence, he set forth to create an institution that would shape the future of engineering education and research.

                                    </p>
                                    <h5>
                                        Early Endeavors and Growth
                                    </h5>

                                    <p>
                                        In its nascent years, Armelubawa College of Engineering began as a humble institution, offering a select range of engineering programs tailored to meet the burgeoning demand for skilled professionals in the field. With a dedicated faculty, state-of-the-art facilities, and a curriculum steeped in practical application, the college quickly gained recognition for its commitment to quality education and innovation.
                                    </p>
                                    <h5>
                                        Expansion and Evolution
                                    </h5>

                                    <p>
                                        As the college flourished, so too did its ambitions. Under the visionary leadership of Armelubawa Hunbessen, the institution expanded its offerings, introducing new disciplines and programs to cater to the diverse needs of the engineering landscape. From electrical engineering to mechanical engineering, computer science to civil engineering, the college embraced diversity and innovation, paving the way for interdisciplinary collaboration and groundbreaking research.

                                    </p>
                                    <h5>
                                        A Tradition of Excellence
                                    </h5>
                                    <p>
                                        Throughout its history, Armelubawa College of Engineering has remained steadfast in its pursuit of excellence, upholding rigorous academic standards, fostering a culture of innovation, and instilling in its students a passion for engineering. Its faculty members, renowned scholars and industry experts alike, have played a pivotal role in shaping the minds of future engineers, imbuing them with the knowledge, skills, and values needed to excel in their chosen field.

                                    </p>
                                    <h5>
                                        Vision for Engineering Education
                                    </h5>

                                    <h5>
                                        Impact and Legacy
                                    </h5>
                                    <p>
                                        The impact of Armelubawa College of Engineering extends far beyond the confines of its campus, leaving an indelible mark on communities, industries, and societies worldwide. Its alumni, numbering in the thousands, have gone on to lead illustrious careers, spearheading transformative projects, and pioneering advancements in engineering that have shaped the course of history.
                                    </p>

                                    <h5>
                                        Looking Ahead
                                    </h5>

                                    <p>
                                        As Armelubawa College of Engineering commemorates over four decades of innovation and achievement, it remains steadfast in its commitment to pushing the boundaries of knowledge, fostering collaboration, and addressing the grand challenges facing society. With eyes set firmly on the future, the college continues to inspire the next generation of engineering leaders, preparing them to confront the complexities of tomorrow with courage, creativity, and ingenuity.

                                    </p>
                                    <h5>
                                        Join Us in Engineering the Future
                                    </h5>
                                    <p>
                                        Whether you are a prospective student, a dedicated faculty member, or a proud alum, we invite you to join us on this extraordinary journey of engineering excellence at Armelubawa College of Engineering. Together, let us continue to innovate, collaborate, and engineer a brighter future for generations to come.
                                    </p>
                                    
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default History;
