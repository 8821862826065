import React from "react";
import './President.css'
import img1 from '../../utils/images/arm3.png';

function President() {
    return (
        <>
            <div className="president">
                <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
                    <div className='container d-flex flex-column align-items-center'>
                        <div className='d-flex flex-column flex-sm-column align-items-center'>
                         <h1>
                            Welcome to the President Office
                         </h1>
                            {/* <Link to="/courses">
                                <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Our Courses</button>
                            </Link>
                            <Link to="/contact">
                                <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                            </Link> */}
                        </div>
                    </div>
                </header>



                <div className="container-md my-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-10">
                            <div className="text-center">
                            </div>
                            <div>
                                <div className="clearfix">
                                    <img src={img1} class="col-md-6 float-md-end mb-3 ms-md-3 shadow-lg img-fluid" alt="..." />
                                    <h3 className="fw-bold">
                                        Meet Professor Franz Horst

                                    </h3>
                                    <h4 className="text-muted"> President of Armelubawa College of Engineering</h4>
                                    <p>
                                        Professor Franz Horst stands at the helm of Armelubawa College of Engineering as its esteemed President, bringing with him a wealth of knowledge, experience, and a profound dedication to engineering education. With a distinguished background as a Professor of Electrical and Mechanical Engineering, Professor Horst embodies the spirit of innovation and academic excellence that defines our institution.

                                    </p>
                                    <h5>
                                        A Story of Dedication and Innovation
                                    </h5>

                                    <p>
                                        Professor Horst's journey to the presidency is one marked by dedication, innovation, and a relentless pursuit of excellence. Born from humble beginnings, he discovered his passion for engineering at a young age, inspired by the possibilities of technology and the promise of creating a better world through innovation.

                                    </p>
                                    <p>
                                        Throughout his academic and professional career, Professor Horst has distinguished himself as a visionary leader in the field of engineering. His groundbreaking research in Electrical and Mechanical Engineering has earned him international acclaim, while his commitment to teaching and mentorship has shaped the lives and careers of countless students.

                                    </p>
                                    <h5>
                                        A Champion of Diversity and Inclusion
                                    </h5>

                                    <p>
                                        As President of Armelubawa College of Engineering, Professor Horst is committed to fostering a culture of diversity, equity, and inclusion within our institution. He believes that a diverse and inclusive community is essential for fostering creativity, innovation, and academic excellence. Under his leadership, the college has launched initiatives to support underrepresented groups in STEM and create opportunities for all students to succeed.


                                    </p>
                                    <h5>
                                        Leading Armelubawa College of Engineering into the Future
                                    </h5>
                                    <p>
                                        Under Professor Horst's visionary leadership, Armelubawa College of Engineering is poised to embark on a new era of growth, innovation, and impact. With a focus on interdisciplinary collaboration, industry partnerships, and cutting-edge research, Professor Horst is leading the college towards a future where engineering plays a central role in addressing the grand challenges facing society.

                                    </p>
                                    <h5>
                                        Get in Touch with Otto Claus
                                    </h5>
                                    <p>
                                        Do you have questions, ideas, or feedback for Professor Franz Horst? He welcomes the opportunity to connect with students, faculty, staff, alumni, and members of the community. Reach out to him directly at:
                                    </p>
                                    <p>Email: <a href='mailto:president@armelubawaengineering.org'>president@armelubawaengineering.org</a></p>
                                    <p className="text-danger">
                                        Join us in celebrating the leadership and vision of Professor Franz Horst as we continue to push the boundaries of engineering education and research at Armelubawa College of Engineering. Together, let us engineer a brighter future for all.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default President;