import React from 'react';
import './Postgrad.css';
import img1 from '../../utils/images/arm7.jpg';

const Postgrad = () => {
    return (
        <>
            <div className='postgrad'>
                <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
                    <div className='container d-flex flex-column align-items-center'>
                        <div className='d-flex flex-column flex-sm-column align-items-center'>
                            <p>
                                Welcome to Armelubawa College of Engineering: Elevate Your Expertise with a Master's Degree
                            </p>
                            {/* <Link to="/courses">
                                <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Our Courses</button>
                            </Link>
                            <Link to="/contact">
                                <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                            </Link> */}
                            <p>Congratulations on taking the next step in your academic journey! At Armelubawa College of Engineering, we invite you to immerse yourself in an unparalleled experience of advanced learning, cutting-edge research, and professional growth. Discover the pathway to unlocking your full potential through our Master's programs in Engineering, where innovation and excellence converge.</p>
                        </div>
                    </div>
                </header>
                <div className="container-md my-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-9 border b-2 shadow-sm rounded p-5">
                            <div className="text-center">
                                <h4 className='my-4'>Admission Requirements: Setting the Stage for Success</h4>
                            </div>
                            <div className="col-md-9">
                                <p>
                                    Our Master's programs are designed for those who seek to push the boundaries of knowledge in their chosen field. The admission requirements are meticulously crafted to identify individuals with a passion for research, a commitment to academic excellence, and a desire to make a significant impact in the world of engineering.
                                </p>
                                <p>
                                    Programs Offered: Explore our Master's programs in Electrical Engineering, Mechanical Engineering, Computer Science, and Civil Engineering. Each program is tailored to provide specialized knowledge and skills essential for leadership in the evolving engineering landscape.

                                </p>
                                <p>
                                    Prerequisites: To qualify for admission, applicants must possess a relevant Bachelor's degree in engineering or a related field. A strong academic background and a demonstrated interest in research are highly valued.
                                </p>
                                <p>
                                    English Proficiency Test: Proficiency in English is paramount for success in our Master's programs. Applicants are required to submit English proficiency test scores. Accepted tests include IELTS (Minimum Score: 7), Duolingo English Test (Minimum Score: 100), and TOEFL (Minimum Score: 90 - Internet-based test).
                                </p>
                                <p>
                                    GRE (Graduate Record Examination): While the GRE is not mandatory, a competitive score can strengthen your application and showcase your readiness for advanced academic study.
                            
                                </p>
                                <p>
                                    Statement of Purpose (SOP): Craft a compelling Statement of Purpose that outlines your academic and professional goals, research interests, and how our Master's program aligns with your aspirations.
                                </p>
                                <p>
                                    Letters of Recommendation: Submit letters of recommendation from academic or professional references who can attest to your qualifications and potential for success in a Master's program.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-xxl">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-10">
                            <div className="text-center">
                                <h4 className='my-3'>
                                    Application Process: Paving the Way for Your Future
                                </h4>
                                
                            </div>
                            <div>
                                <p>
                                    All applications must be submitted through our official admission email: gradadmission@armelubawaengineering.org. Ensure your application package includes the following documents:
                                </p>

                            </div>
                            <div className='container-md'>
                                <div className='col-md-8 my-4 '>
                                    <ul class="list-group">
                                        <li class="list-group-item">Completed application form</li>
                                        <li class="list-group-item">Transcripts and certificates</li>
                                        <li class="list-group-item">English proficiency test score report</li>
                                        <li class="list-group-item">GRE score report (if applicable)</li>
                                        <li class="list-group-item">Statement of Purpose</li>
                                        <li class="list-group-item">Letters of Recommendation</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>  
                </div>
                <div className="container-lg my-5 bg-light p-4">
                    <div className="row g-5 justify-content-center p-4">
                        <div className="col-sm-6 border-start p-4">
                            <div>
                                <h5>Research Opportunities: Shaping the Future of Engineering</h5>
                                <p>Engage in groundbreaking research opportunities with our esteemed faculty members. Armelubawa College of Engineering is committed to fostering a research-centric environment, providing you with the tools and resources to contribute to advancements in your chosen field.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 border-start p-4">
                            <div>
                                <h5>Connect with Faculty: A Collaborative Learning Experience</h5>
                                <p>Our faculty members are leading experts in their respective fields. Connect with them through research projects, seminars, and collaborative initiatives, gaining insights that will enrich your academic and professional journey.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 border-start p-4">
                            <div>
                                <h5>Submit Your Application: Elevate Your Engineering Expertise!</h5>
                                <p>Ready to embark on a transformative Master's journey at Armelubawa College of Engineering? Submit your application to gradadmission@armelubawaengineering.org and take the first step toward unlocking new heights of knowledge, innovation, and leadership in engineering.</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container-md my-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-10">
                            <div className="text-center">
                            </div>
                            <div>
                                <div className="clearfix">
                                    <img src={img1} class="col-md-6 float-md-end mb-3 ms-md-3 shadow-lg" alt="..." />
                                    <p>
                                        Otto Claus serves as the Postgraduate Admission Officer at Armelubawa College of Engineering, where he plays a pivotal role in guiding aspiring engineers through the admissions process. With a wealth of experience and a deep understanding of the academic landscape, Otto is committed to helping students embark on their postgraduate journey with confidence and enthusiasm.
                                    </p>
                                    <p>
                                        Otto Claus is a dedicated professional with a profound interest in engineering, particularly in exploring the intricate phenomena within Electrical and Mechanical domains. With a comprehensive understanding of these disciplines, Otto serves as the Postgraduate Admission Officer at Armelubawa College of Engineering.

                                    </p>
                                    <h5>
                                        Professional Background
                                    </h5>

                                    <p>
                                        Otto's journey into the world of engineering began with a relentless curiosity about the underlying principles governing Electrical and Mechanical phenomena. His academic pursuits led him to delve deep into the complexities of these domains, where he developed a keen interest in understanding their underlying mechanisms and behaviors.

                                    </p>
                                    <h5>
                                        Research Focus
                                    </h5>

                                    <p>
                                        Otto's research focus lies at the intersection of Electrical and Mechanical engineering, where he investigates the interplay between these two domains to enhance our understanding and harness their potential for innovative solutions. His work encompasses [insert brief description of research focus], aiming to address real-world challenges and drive advancements in engineering technology.

                                    </p>
                                    <h5>
                                        Role as Postgraduate Admission Officer
                                    </h5>
                                    <p>
                                        In his role as Postgraduate Admission Officer, Otto leverages his expertise to guide aspiring engineers through the admissions process. He is committed to identifying individuals with a passion for research, a thirst for knowledge, and a drive to make meaningful contributions to the field of engineering.
                                    </p>
                                    <h5>
                                        Vision for Engineering Education
                                    </h5>

                                    <p>
                                        Otto is passionate about fostering a diverse and inclusive learning environment where students are empowered to explore, innovate, and collaborate. He believes that by nurturing a community of passionate and driven individuals, we can unlock the full potential of engineering to address global challenges and shape a better future for generations to come.
                                    </p>

                                    <p>
                                        And yet, here you are, still persevering in reading this placeholder text, hoping for some more insights, or some hidden easter egg of content. A joke, perhaps. Unfortunately, there's none of that here.
                                    </p>
                                    <h5>
                                        Get in Touch with Otto Claus
                                    </h5>
                                    <p>
                                        Are you considering pursuing postgraduate studies in engineering? Do you have questions about the admissions process or want to learn more about research opportunities at Armelubawa College of Engineering? Otto Claus is here to assist you!                                    </p>
                                    <p>Email: <a href='mailto:otto.claus@armelubawaengineering.org'>otto.claus@armelubawaengineering.org</a></p>
                                    <p className='text-danger'>Embark on an exciting journey of discovery and innovation in engineering with Otto Claus and the team at Armelubawa College of Engineering. We look forward to welcoming you and supporting you on your path to success in the dynamic field of engineering!</p>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )

}

export default Postgrad;