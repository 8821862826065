import React from "react";
import { Link } from "react-router-dom";
import "./Electrical.css";
import img1 from '../../utils/images/arm13.jpg';

function Electrical() {
    return (
        <>
            <div className="electrical">
                <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
                    <div className='container d-flex flex-column align-items-center'>
                        <div className='d-flex flex-column flex-sm-column align-items-center'>
                            <p className='fw-semibold'>Department of Electrical Engineering</p>
                            <p className='fw-semibold'>
                                Welcome to the Department of Electrical Engineering at Armelubawa College of Engineering! Our department is committed to providing a dynamic and innovative learning environment that prepares students for successful careers in the field of electrical engineering.
                            </p>
                            {/* <Link to="/courses">
                                <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Our Courses</button>
                            </Link>
                            <Link to="/contact">
                                <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                            </Link> */}
                        </div>
                    </div>
                </header>

                {/* Mission */}
                <div className="container-md">
                    <div className="row align-items-center justify-content-center my-5">
                        <div className="col-sm-8 border p-5">
                            <div className='text-center'>
                                <h4>Mission</h4>

                            </div>
                            <div>
                                <p>
                                    Our mission is to educate and inspire the next generation of electrical engineers through rigorous academic programs, cutting-edge research, and hands-on practical experience. We strive to cultivate critical thinking, problem-solving skills, and a passion for lifelong learning among our students.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Academic Program */}
                <div className="container-md">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8">
                            <div>
                                <h4 className="my-4">Academic Programs</h4>
                            </div>
                            <div className="col-md-8">
                                <ul class="list-group">
                                    <li class="list-group-item">Bachelor of Science in Electrical Engineering (BSEE)</li>
                                    <li class="list-group-item">Master of Science in Electrical Engineering (MSEE)</li>
                                    <li class="list-group-item">Ph.D. in Electrical Engineering (Ph.D. EE)</li>
                                </ul>
                            </div>
                            <h4 className="my-4">Our curriculum covers a wide array of topics, including:</h4>
                            <div className="col-md-7">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">Circuits and Electronics</li>
                                    <li class="list-group-item">Power Systems</li>
                                    <li class="list-group-item">Control Systems</li>
                                    <li class="list-group-item">Digital Signal Processing</li>
                                    <li class="list-group-item">Renewable Energy Systems</li>
                                    <li class="list-group-item">Embedded Systems</li>
                                    <li class="list-group-item">Electric Machines and Drives</li>
                                    <li class="list-group-item">Communication Systems</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Faculty */}
                <div className="container-md my-5">
                    <div className="row align-items-center">
                        <div className="col-md-8 border-start border-2 p-4 bg-light">
                            <div>
                                <h3>Our Faculty</h3>
                            </div>
                            <div>
                                <p>
                                    Our faculty members are experts in their respective fields, conducting cutting-edge research in areas such as renewable energy, power electronics, artificial intelligence, and advanced control systems. Students have the opportunity to engage in research projects alongside faculty mentors, gaining valuable hands-on experience and contributing to the advancement of knowledge in electrical engineering.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Facilities */}
                <div className="container-md my-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 border-start border-2 p-4">
                            <div>
                                <h3>Our Facilities</h3>
                            </div>
                            <div>
                                <p>
                                    The Department of Electrical Engineering is equipped with state-of-the-art laboratories and facilities to support both teaching and research activities. Our laboratories include:

                                </p>
                                <div className="col-md-6">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">Power Systems Laboratory</li>
                                        <li class="list-group-item">Electronics Laboratory</li>
                                        <li class="list-group-item">Control Systems Laboratory</li>
                                        <li class="list-group-item">Renewable Energy Laboratory</li>
                                        <li class="list-group-item">Digital Signal Processing Laboratory</li>
                                    </ul>
                                </div>
                                <p className="my-3">
                                    These facilities provide students with hands-on experience and practical skills that are essential for success in the field.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Student Organizations */}
                <div className="container-md my-5">
                    <div className="row align-items-center">
                        <div className="col-md-8 border-start border-2 p-4 bg-light">
                            <div>
                                <h3>Student Organizations</h3>
                            </div>
                            <div>
                                <p>
                                    The department actively supports student organizations such as the Institute of Electrical and Electronics Engineers (IEEE) student chapter, providing opportunities for networking, professional development, and collaboration on projects and competitions.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Industry Partnerships and Internships */}
                <div className="container-md my-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 border-start border-2 p-4">
                            <div>
                                <h3>Industry Partnerships and Internships</h3>
                            </div>
                            <div>
                                <p>
                                    We have strong partnerships with industry leaders, offering students opportunities for internships, co-op programs, and industry-sponsored projects. These partnerships provide students with real-world experience and connections that enhance their career prospects upon graduation.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Contact us */}

                {/* Person */}

                <div className="container-md my-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-10">
                            <div className="text-center">
                            </div>
                            <div>
                                <div className="row align-items-center">
                                    <img src={img1} class="col-md-6 float-md-end mb-3 ms-md-3 shadow-lg" alt="..." />
                                    <h3 className="my-2">Department Chair </h3>
                                    <h3 className="fw-semibold">Friedrich Heinrich</h3>
                                    <p></p>
                                    <p>Email: <a href='mailto:depelectrical@armelubawaengineering.org'>depelectrical@armelubawaengineering.org</a></p>
                                    <div className="col-sm-6">
                                        <p>We invite you to explore the Department of Electrical Engineering at Armelubawa College of Engineering and discover the exciting opportunities available to our students. For more information, please contact:</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>






            </div>

        </>
    )

}

export default Electrical;