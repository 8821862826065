import React from 'react';
import './Home.css';
import { Link, Routes, Route } from 'react-router-dom';
import ChooseSection from '../../components/ChooseSection/ChooseSection';
import StartCoursesImg from '../../utils/images/start-courses-img.jpg';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';
import { Card } from 'react-bootstrap';
import Blog1Img from '../../utils/images/blog1-img.jpg';
import Blog2Img from '../../utils/images/blog2-img.jpg';
import Blog3Img from '../../utils/images/blog3-img.jpg';
import Undergrad from '../../pages/Undergrad/Undergrad';
import Postgrad from '../../pages/Postgrad/Postgrad';
import Stempro from '../../pages/Stempro/Stempro';

const blogs = [
    {
        id: 1,
        img: [Blog1Img],
        title: 'Degree Offerings',
        description: "We are proud to offer a range of degree programs to suit every student's aspirations. From our rigorous Bachelor of Science in Engineering program to our advanced Master of Science in Engineering curriculum, and even opportunities for pursuing a Doctorate (PhD) in Engineering, our institution provides a pathway for lifelong learning and professional advancement."
    },
    {
        id: 2,
        img: [Blog2Img],
        title: 'Tuition-Free Education',
        description: "At Armelubawa College of Engineering, we are committed to providing accessible education to talented individuals from all backgrounds. As part of our mission to promote equal opportunities, we offer 100% tuition-free education or full scholarships to eligible students who meet our criteria. We believe that financial barriers should never hinder a student's ability to pursue their dreams and unleash their potential in the field of engineering."

    },
    {
        id: 3,
        img: [Blog3Img],
        title: 'Summer STEM Program',
        description: "In addition to formal education, Armelubawa College of Engineering is proud to offer a free summer STEM program for high school students. This program provides an exciting opportunity for young minds to explore the fascinating world of science, technology, engineering, and mathematics in a hands-on and engaging environment. Click here to learn more."
    },
    {
        id: 4,
        img: [Blog1Img],
        title: "Community Service",
        description: "At Armelubawa College of Engineering, we understand the importance of giving back to our community. Through various outreach initiatives and volunteer programs, our students, faculty, and staff actively engage in community service projects aimed at making a positive impact in Antwerp and beyond. From environmental conservation efforts to mentoring programs for underprivileged youth, we are committed to using our skills and expertise to serve others and create a more equitable and sustainable world. Click here for more"
    },
    {
        id: 5,
        img: [Blog2Img],
        title: "Beyond Academics",
        description: "Beyond the classroom, Armelubawa College of Engineering offers a vibrant campus life enriched with diverse extracurricular activities, research opportunities, and community engagement initiatives. Our students have access to internships, industry partnerships, and study abroad programs, ensuring a holistic educational experience that prepares them to thrive in a dynamic global society."
    },
    {
        id: 6,
        img: [Blog3Img],
        title: "Innovation Hub",
        description: "Armelubawa College of Engineering takes pride in fostering innovation and entrepreneurship among its students and faculty. Our Innovation Hub provides a collaborative space where ideas are transformed into tangible solutions, fostering creativity, interdisciplinary collaboration, and real-world impact. From incubating startups to hosting innovation challenges, we empower our community to push the boundaries of engineering and drive positive change. Explore our Innovation Hub and unleash your potential to innovate, create, and lead."
    }
];

function Home() {
    return (
        <div className='home-page'>
            <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
                <div className='container d-flex flex-column align-items-center'>
                    <h2>Welcome To</h2>
                    <h1 className='text-center fw-semibold'>Armelubawa College of Engineering</h1>
                    <p>Unlock Your Potential: Where Knowledge Meets Opportunity</p>
                    <div className='d-flex flex-column flex-sm-row align-items-center'>
                        <Link to="/departments">
                            <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Our Departments</button>
                        </Link>
                        <Link to="/contact">
                            <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                        </Link>
                    </div>
                </div>
            </header>
            <div className="container-lg">
                <div className="row align-items-center justify-content-center my-5">
                    <div className="col-md-10 bg-light p-5 fs-6">
                        <div>
                            <p>At Armelubawa College of Engineering, we offer a diverse array of engineering fields, spanning from traditional disciplines to cutting-edge specialties. Whether your passion lies in Mechanical Engineering, Electrical Engineering, Civil Engineering, Computer Engineering, Chemical Engineering, or beyond, our expert faculty and state-of-the-art facilities ensure that you receive the highest quality education tailored to your interests and career goals.</p>

                        </div>
                    </div>

                </div>
            </div>
            <div className="py-5">
                <ChooseSection />
            </div>

            <div className='py-5 bg-light'>
                <div className="container">
                    <div className='row d-flex align-items-center justify-content-around my-5'>
                        <div className='col-lg-5'>
                            <h2 className='text-capitalize'>our programs</h2>
                            <p>At Armelubawa College of Engineering, we offer a diverse array of engineering fields, spanning from traditional disciplines to cutting-edge specialties. Whether your passion lies in Mechanical Engineering, Electrical Engineering, Civil Engineering, Computer Engineering, Chemical Engineering, or beyond, our expert faculty and state-of-the-art facilities ensure that you receive the highest quality education tailored to your interests and career goals.
                            </p>
                            {/* <Link to="/courses">
                                <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Learn More</button>
                            </Link> */}
                        </div>
                        <div className='col-lg-5 mt-5 mt-lg-0 my-5'>
                            <img src={StartCoursesImg} className='img-fluid' alt="" />
                        </div>
                        <div className="container-md">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-6">

                                    <div class="list-group ms-1">
                                        <li class="list-group-item list-group-item-action bg-secondary" aria-current="true">
                                            Admissions
                                        </li>
                                        <a href="undergrad" class="list-group-item list-group-item-action">Undergraduate Admission</a>
                                        <a href="postgrad" class="list-group-item list-group-item-action">Postgraduate admission</a>
                                        <a href="stempro" class="list-group-item list-group-item-action">Summer STEM Program</a>

                                    </div> 
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="py-5">
                <FaqAccordion />
            </div>

            <div className='blog-section text-light py-5'>
                <div className='container d-flex flex-column align-items-center'>
                    <h2 className='text-center text-capitalize mb-5'>What you get in Armelubawa College of Engineering</h2>
                    <div className='row g-4'>
                        {blogs.map((blog) => (
                            <div key={blog.id} className='col-md-6 col-lg-4'>
                                <Link to="/blog" className='text-decoration-none'>
                                    <Card className='h-100 shadow scale-hover-effect'>
                                        {/* <Card.Img variant="top" src={blog.img} /> */}
                                        <Card.Body className='p-md-5'>
                                            <Card.Title className='fw-semibold'>{blog.title}</Card.Title>
                                            <Card.Text>{blog.description}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </div>
                        ))}
                    </div>
                    {/* <Link to="/blog">
                        <button type='button' className='btn btn-danger btn-lg mt-5'>Read More Blogs</button>
                    </Link> */}
                </div>
            </div>
        </div>
        
    )
}


export default Home;