import React from 'react';
import './FaqAccordion.css';
import Accordion from 'react-bootstrap/Accordion';

const questions = [
    {
        id: 1,
        question: 'First question ?',
        answer: "This is an answer to the first question."
    },
    {
        id: 2,
        question: 'Second question ?',
        answer: "This is an answer to the second question."
    },
    {
        id: 3,
        question: 'Third question ?',
        answer: "This is an answer to the third question."
    },
    {
        id: 4,
        question: 'Fourth question ?',
        answer: "This is an answer to the fourth question."
    }
];



function FaqAccordion() {
  return (
    <div className='faq-section'>
        <div className='container d-flex flex-column align-items-center'>
            <h2 className='text-center text-capitalize mb-5'>Frequently asked questions</h2>
            
            
            
              <Accordion defaultActiveKey="0" flush>
                  {/* Undergraduate Programs */}
                  <Accordion.Item eventKey='0'>
                      <Accordion.Header>What undergraduate programs does Armelubawa College of Engineering offer?</Accordion.Header>
                      <Accordion.Body>
                          The college offers undergraduate programs in various engineering disciplines, including electrical engineering, mechanical engineering, computer science, and civil engineering.
                      </Accordion.Body>
                  </Accordion.Item>

                  {/* Admission Requirements for Undergraduate Programs */}
                  <Accordion.Item eventKey='1'>
                      <Accordion.Header>What are the admission requirements for undergraduate programs?</Accordion.Header>
                      <Accordion.Body>
                          Admission requirements typically include academic transcripts, standardized test scores (SAT or equivalent), English proficiency test scores (IELTS, TOEFL, Duolingo English Test or equivalent), letters of recommendation, and a statement of purpose.
                      </Accordion.Body>
                  </Accordion.Item>

                  {/* Applying for Undergraduate Admission */}
                  <Accordion.Item eventKey='2'>
                      <Accordion.Header>How do I apply for undergraduate admission?</Accordion.Header>
                      <Accordion.Body>
                          All undergraduate applications must be submitted through the college's official admission email, with required documents such as completed application forms, transcripts, test scores, and letters of recommendation.
                      </Accordion.Body>
                  </Accordion.Item>

                  {/* Scholarships for Undergraduate Students */}
                  <Accordion.Item eventKey='3'>
                      <Accordion.Header>Does Armelubawa College of Engineering offer scholarships for undergraduate students?</Accordion.Header>
                      <Accordion.Body>
                          The college offers various scholarships and financial aid options for undergraduate students based on academic merit, financial need, and other criteria. Applicants are encouraged to explore available opportunities and eligibility requirements.
                      </Accordion.Body>
                  </Accordion.Item>

                  {/* Graduate Programs */}
                  <Accordion.Item eventKey='4'>
                      <Accordion.Header>What graduate programs does Armelubawa College of Engineering offer?</Accordion.Header>
                      <Accordion.Body>
                          The college offers graduate programs in fields such as electrical engineering, mechanical engineering, computer science, and civil engineering at the master's and doctoral levels.
                      </Accordion.Body>
                  </Accordion.Item>

                  {/* Admission Requirements for Graduate Programs */}
                  <Accordion.Item eventKey='5'>
                      <Accordion.Header>What are the admission requirements for graduate programs?</Accordion.Header>
                      <Accordion.Body>
                          Admission requirements typically include a relevant bachelor's degree in engineering or a related field, standardized test scores (GRE or equivalent), English proficiency test scores (IELTS, TOEFL, Duolingo English Test or equivalent), letters of recommendation, a statement of purpose, and sometimes a research proposal.
                      </Accordion.Body>
                  </Accordion.Item>

                  {/* Applying for Graduate Admission */}
                  <Accordion.Item eventKey='6'>
                      <Accordion.Header>How do I apply for graduate admission?</Accordion.Header>
                      <Accordion.Body>
                          All graduate applications must be submitted through the college's official admission email, with required documents such as completed application forms, transcripts, test scores, letters of recommendation, and a statement of purpose.
                      </Accordion.Body>
                  </Accordion.Item>

                  {/* Assistantships and Fellowships for Graduate Students */}
                  <Accordion.Item eventKey='7'>
                      <Accordion.Header>Does Armelubawa College of Engineering offer assistantships or fellowships for graduate students?</Accordion.Header>
                      <Accordion.Body>
                          The college offers various assistantship and fellowship opportunities for graduate students, including research assistantships, teaching assistantships, and fellowships. Applicants are encouraged to inquire about available opportunities and eligibility requirements.
                      </Accordion.Body>
                  </Accordion.Item>

                  {/* Summer STEM Program */}
                  <Accordion.Item eventKey='8'>
                      <Accordion.Header>What is the Summer STEM Program at Armelubawa College of Engineering?</Accordion.Header>
                      <Accordion.Body>
                          The Summer STEM Program is a free program offered to high school students interested in exploring STEM fields through hands-on learning, workshops, and projects.
                      </Accordion.Body>
                  </Accordion.Item>

                  {/* Admission Requirements for Summer STEM Program */}
                  <Accordion.Item eventKey='9'>
                      <Accordion.Header>What are the admission requirements for the Summer STEM Program?</Accordion.Header>
                      <Accordion.Body>
                          Admission requirements typically include completing a pre-college STEM test, submitting an application form, and meeting any additional criteria specified by the program.
                      </Accordion.Body>
                  </Accordion.Item>

                  {/* Applying for Summer STEM Program */}
                  <Accordion.Item eventKey='10'>
                      <Accordion.Header>How do I apply for the Summer STEM Program?</Accordion.Header>
                      <Accordion.Body>
                          All applications for the Summer STEM Program must be submitted through the program's official email address, with required documents as specified in the application guidelines.
                      </Accordion.Body>
                  </Accordion.Item>
              </Accordion>
        </div>
    </div>
  )
}

export default FaqAccordion;