import React from "react";
import "./Mechanical.css";
import img1 from '../../utils/images/arm5.jpg';


function Mechanical() {
    return (
        <>
            <div className="mechanical">
                <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
                    <div className='container d-flex flex-column align-items-center'>
                        <div className='d-flex flex-column flex-sm-column align-items-center'>
                            <p className='fw-semibold'>Department of Mechanical Engineering</p>
                            <p className='fw-semibold'>
                                The Department of Mechanical Engineering at Armelubawa College of Engineering offers a comprehensive range of undergraduate and graduate programs designed to provide students with a solid foundation in mechanical engineering fundamentals while also offering opportunities for specialization, hands-on learning, and research experience.

                            </p>

                            {/* <Link to="/courses">
                                <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Our Courses</button>
                            </Link>
                            <Link to="/contact">
                                <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                            </Link> */}
                        </div>
                    </div>
                </header>



                {/* Mission */}
                <div className="container-md">
                    <div className="row align-items-center justify-content-center my-5">
                        <div className="col-sm-8 border p-5">
                            <div className='text-center'>
                                <h4>Mission</h4>

                            </div>
                            <div>
                                <p>
                                    Our mission is to educate and empower future mechanical engineers with the knowledge, skills, and ethics necessary to tackle real-world engineering challenges. Through our rigorous academic programs, collaborative research initiatives, and industry partnerships, we strive to cultivate leaders who will shape the future of the mechanical engineering profession.

                                </p>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Academic Program */}
                <div className="container-md">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8">
                            <div>
                                <h4 className="my-4">Academic Programs</h4>
                                <p>
                                    The Department of Mechanical Engineering offers a comprehensive range of undergraduate and graduate programs designed to prepare students for successful careers in various industries. Our programs include:

                                </p>
                            </div>
                            <div className="col-md-8">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">Bachelor of Science in Mechanical Engineering (BSME)</li>
                                    <li class="list-group-item">Master of Science in Mechanical Engineering (MSME)</li>
                                    <li class="list-group-item">Ph.D. in Mechanical Engineering (Ph.D. ME)</li>
                                </ul>

                            </div>
                            <h4 className="my-4">Our curriculum covers a wide array of topics, including:</h4>
                            <div className="col-md-7">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">Thermodynamics</li>
                                    <li class="list-group-item">Fluid Mechanics</li>
                                    <li class="list-group-item">Solid Mechanics</li>
                                    <li class="list-group-item">Heat Transfer</li>
                                    <li class="list-group-item">Materials Science</li>
                                    <li class="list-group-item">Manufacturing Processes</li>
                                    <li class="list-group-item">Robotics and Automation</li>
                                    <li class="list-group-item">Sustainable Energy Systems</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

                {/* Faculty */}
                <div className="container-md my-5">
                    <div className="row align-items-center">
                        <div className="col-md-8 border-start border-2 p-4 bg-light">
                            <div>
                                <h3>Our Faculty</h3>
                            </div>
                            <div>
                                <p>
                                    Our faculty members are renowned experts in their fields, conducting cutting-edge research in areas such as advanced materials, biomechanics, robotics, and renewable energy. Through interdisciplinary collaboration and innovative research projects, students have the opportunity to explore and contribute to the forefront of mechanical engineering knowledge.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Facilities */}
                <div className="container-md my-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 border-start border-2 p-4">
                            <div>
                                <h3>Our Facilities</h3>
                            </div>
                            <div>
                                <p>
                                    The Department of Mechanical Engineering is equipped with state-of-the-art laboratories and facilities to support teaching and research activities. Our facilities include:

                                </p>
                                <div className="col-md-6">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">Advanced Materials Laboratory</li>
                                        <li class="list-group-item">Fluid Mechanics Laboratory</li>
                                        <li class="list-group-item">Robotics Laboratory</li>
                                        <li class="list-group-item">Thermal Sciences Laboratory</li>
                                        <li class="list-group-item">Machine Shop</li>
                                    </ul>

                                </div>
                                <p className="my-3">
                                    These facilities provide students with hands-on experience and practical skills essential for success in the field of mechanical engineering.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Student Organizations */}
                <div className="container-md my-5">
                    <div className="row align-items-center">
                        <div className="col-md-8 border-start border-2 p-4 bg-light">
                            <div>
                                <h3>Student Organizations</h3>
                            </div>
                            <div>
                                <p>
                                    The department actively supports student organizations such as the American Society of Mechanical Engineers (ASME) student chapter, providing opportunities for networking, professional development, and collaboration on projects and competitions.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Industry Partnerships and Internships */}
                <div className="container-md my-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 border-start border-2 p-4">
                            <div>
                                <h3>Industry Partnerships and Internships</h3>
                            </div>
                            <div>
                                <p>
                                    We have established strong partnerships with industry leaders, offering students opportunities for internships, co-op programs, and industry-sponsored projects. These partnerships provide students with valuable real-world experience and connections that enhance their career prospects upon graduation.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Contact us */}

                {/* Person */}

                <div className="container-md my-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-10">
                            <div className="text-center">
                            </div>
                            <div>
                                <div className="row align-items-center">
                                    <img src={img1} class="col-md-6 float-md-end mb-3 ms-md-3 shadow-lg" alt="..." />
                                    <h3 className="my-2">Department Chair </h3>
                                    <h3 className="fw-semibold">Dr. Otto Ernst</h3>
                                    <p></p>
                                    <p>Email: <a href='mailto:depmechanical@armelubawaengineering.org'>depmechanical@armelubawaengineering.org</a></p>
                                    <div className="col-sm-6">
                                        <p>
                                            We invite you to explore the Department of Mechanical Engineering at Armelubawa College of Engineering and discover the exciting opportunities available to our students. For more information, please contact:
                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Mechanical;