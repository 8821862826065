import React from 'react';
import './Stempro.css';
import img1 from '../../utils/images/arm6.jpg';
const Stempro = () => {
    return (
        <>
            <div className='stempro'>
                <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
                    <div className='container d-flex flex-column align-items-center'>
                        <div className='d-flex flex-column flex-sm-column align-items-center'>
                            <p className='fw-semibold'>Summer STEM Program at Armelubawa College of Engineering
                            </p>
                            <p className='fw-semibold'>Welcome to the Summer STEM Program at Armelubawa College of Engineering! Our program is designed to inspire and engage high school students in the exciting world of Science, Technology, Engineering, and Mathematics (STEM). With hands-on activities, interactive workshops, and immersive experiences, we aim to ignite curiosity, foster creativity, and spark a passion for STEM education.
                            </p>
                            {/* <Link to="/courses">
                                <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Our Courses</button>
                            </Link>
                            <Link to="/contact">
                                <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                            </Link> */}
                        </div>
                    </div>
                </header>

                <div className="container-md">
                    <div className="row align-items-center justify-content-center my-5">
                        <div className="col-sm-8 border p-5">
                            <div className='text-center'>
                                <h4>Mission</h4>

                            </div>
                            <div>
                                <p>
                                    Our mission is to provide high school students with an enriching and immersive summer experience that introduces them to the diverse fields of STEM and inspires them to pursue further education and careers in these areas. We believe that by engaging students in hands-on learning and real-world applications, we can cultivate the next generation of innovators, problem-solvers, and leaders in STEM fields.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container-lg my-5 bg-light p-4">
                    <div className="row g-5 justify-content-center p-4">
                        <div className="col-sm-6 border-start p-4">
                            <div>
                                <h5>Hands-On Projects:</h5>
                                <p>Engage in exciting hands-on projects and experiments that explore various STEM concepts and principles. From building robots to designing bridges, students will have the opportunity to apply their knowledge and skills in a fun and interactive way.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 border-start p-4">
                            <div>
                                <h5>Interactive Workshops:</h5>
                                <p>Participate in interactive workshops led by experienced faculty members and industry professionals. Learn about cutting-edge research, emerging technologies, and career opportunities in STEM fields.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 border-start p-4">
                            <div>
                                <h5>Field Trips and Site Visits:</h5>
                                <p>Explore local industries, research facilities, and engineering firms through field trips and site visits. Gain firsthand insight into the real-world applications of STEM and connect with professionals working in the field.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 border-start p-4">
                            <div>
                                <h5>Mentorship and Guidance:</h5>
                                <p>Receive mentorship and guidance from college students, faculty members, and STEM professionals who will support and inspire you throughout the program. Learn from their experiences, ask questions, and discover the possibilities that await you in STEM.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>



                <div className="container-md">
                    <div className="row">
                        <div className="col-md-8">
                            <div>
                                <h4>Admission Requirements</h4>
                                <p className=''>To be eligible for the Summer STEM Program, students must meet the following admission requirements:</p>
                            </div>
                            <div>
                                <ul class="list-group">
                                    <li class="list-group-item">Completion of a Summer Pre-College STEM Test, designed to assess students' readiness for the program and their proficiency in STEM subjects.</li>
                                    <li class="list-group-item">English Proficiency Test: Proficiency in English is required for participation in the program. Accepted tests include TOEFL, IELTS, and the Duolingo English Test.</li>
                                    <li class="list-group-item">Application Process: Applications must be submitted online through our STEM program email address: <a href="mailto:stemadmissions@armelubawaengineering.org">stemadmissions@armelubawaengineering.org</a>.</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-md ">
                    <div className="row align-items-center justify-content-center ">
                        <div className="col-md-8 border rounded-pill my-5 bg-light p-5">
                            <div className="text-center">
                                <h3 className='my-4'>Certification</h3>
                            </div>
                            <div>
                                <p>
                                    Students who successfully complete the Summer Pre-College STEM courses and meet all program requirements will receive a certificate of completion. This certification serves as a testament to their dedication and achievement in STEM education and can be included in college applications and resumes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-md">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8 grow-on-hover shadow-sm p-4 rounded">
                            <div>
                                Are you ready to embark on an exciting journey of exploration, discovery, and learning? Join us for the Summer STEM Program at Armelubawa College of Engineering and take the first step towards unlocking your potential in STEM. We look forward to welcoming you to our program and inspiring you to pursue your passions in science, technology, engineering, and mathematics!
                            </div>
                        </div>
                    </div>
                </div>

                {/* person */}

                <div className="container-md my-5">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-10">
                            <div className="text-center">
                            </div>
                            <div>
                                <div className="clearfix">
                                    <img src={img1} class="col-md-6 float-md-end mb-3 ms-md-3 shadow-lg" alt="..." />
                                    <p>
                                        Dieter Hans is a dedicated professional with a passion for education and a commitment to fostering the next generation of STEM leaders. Holding a Master's degree in Mechanical Engineering, Dieter brings a wealth of knowledge and experience to his role as the Coordinator and Director of the STEM Summer Programme at Armelubawa College of Engineering.
                                    </p>
                                    <h5>
                                        Professional Background
                                    </h5>

                                    <p>
                                        With a strong foundation in Mechanical Engineering, Dieter's journey into education began with a desire to share his passion for STEM with others. After completing his Master's degree, Dieter embarked on a career in academia, where he quickly distinguished himself as a dynamic and innovative educator. His engaging teaching style, combined with his expertise in Mechanical Engineering, has inspired countless students to pursue further studies and careers in STEM.
                                    </p>
                                    <h5>
                                        Role as Coordinator and Director
                                    </h5>

                                    <p>
                                        In his role as Coordinator and Director of the STEM Summer Programme, Dieter oversees all aspects of the program, from curriculum development to program logistics. He works closely with faculty members, industry partners, and fellow educators to create an enriching and immersive summer experience for high school students interested in STEM.

                                    </p>
                                    <h5>
                                        Vision for the Programme
                                    </h5>
                                    <p>
                                        Dieter's vision for the STEM Summer Programme is rooted in the belief that every student has the potential to excel in STEM given the right opportunities and support. He is committed to providing students with hands-on learning experiences, exposure to cutting-edge research, and mentorship from industry professionals—all of which are essential for cultivating the next generation of STEM leaders.

                                    </p>
                                    <h5>
                                        Impact and Outreach
                                    </h5>

                                    <p>
                                        Under Dieter's leadership, the STEM Summer Programme has grown significantly, attracting students from diverse backgrounds and inspiring them to pursue their passions in STEM. Dieter is passionate about expanding access to STEM education and outreach programs, particularly for underrepresented groups, and he actively seeks out opportunities to collaborate with local schools, community organizations, and industry partners to achieve this goal.
                                    </p>

                                    <h5>
                                        Get in Touch with Dieter Hans
                                    </h5>
                                    <p>
                                        Are you interested in learning more about the STEM Summer Programme at Armelubawa College of Engineering? Do you have questions about the curriculum, admissions process, or program logistics? Dieter Hans is here to help!
                                    </p>
                                    <p>Email: <a href='mailto:dieter.hans@armelubawaengineering.org'>dieter.hans@armelubawaengineering.org</a></p>
                                    <p className='text-danger'>Join us for an unforgettable summer experience filled with exploration, discovery, and learning. With Dieter Hans at the helm, you'll embark on a journey of inspiration and innovation that will prepare you for success in STEM and beyond. We look forward to welcoming you to the STEM Summer Programme at Armelubawa College of Engineering!</p>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )

}

export default Stempro;