import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';
import AboutUsSectionImg from '../../utils/images/about-us-section-img.jpg';
import ChooseSection from '../../components/ChooseSection/ChooseSection';

import Person1 from '../../utils/images/person1.jpg';
import Person2 from '../../utils/images/person2.jpg';
import Person3 from '../../utils/images/person3.jpg';
import Person4 from '../../utils/images/person4.jpg';
import Person5 from '../../utils/images/person5.jpg';
import Person6 from '../../utils/images/person6.jpg';
import Person7 from '../../utils/images/person7.jpg';
import Person8 from '../../utils/images/person8.jpg';
import Person9 from '../../utils/images/person9.jpg';


const persons = [
    {
        id: 1,
        img: [Person1]
    },
    {
        id: 2,
        img: [Person2]
    },
    {
        id: 3,
        img: [Person3]
    },
    {
        id: 4,
        img: [Person4]
    },
    {
        id: 5,
        img: [Person5]
    },
    {
        id: 6,
        img: [Person6]
    },
    {
        id: 7,
        img: [Person7]
    },
    {
        id: 8,
        img: [Person8]
    },
    {
        id: 9,
        img: [Person9]
    },
];

function About() {
    return (
        <>
            <div className='about-page'>
                <header className='height-75'>
                    <div className='container h-100 d-flex flex-column align-items-center justify-content-center text-light'>
                        <h1 className='text-center fw-semibold'>About Us</h1>
                        <p className="text-center w-75"></p>
                        {/* <p className='text-center w-75 mb-5'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error recusandae harum ullam repellat cum? Nisi unde, incidunt natus ut ratione dolore quasi at dolores molestias.</p> */}
                    </div>
                </header>

                <div className='container my-5'>
                    <div className="row">
                        <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                            <h2 className='mb-4 mb-lg-5'>Study with us</h2>
                            <p>Welcome to Armelubawa College of Engineering, a renowned institution dedicated to fostering innovation, excellence, and leadership in engineering education and research. Since our inception in 1981, we have been committed to nurturing the next generation of engineers, equipping them with the skills, knowledge, and values needed to make a meaningful impact in the world.</p>
                            <p className='mb-4 mb-lg-5'>Armelubawa College of Engineering values collaboration with industry partners to bridge the gap between academia and real-world challenges. Through strategic partnerships with leading companies and organizations, our students gain valuable practical experience through internships, co-op programs, and research projects. These collaborations not only enrich our academic programs but also provide opportunities for students to work on cutting-edge projects, solve industry-specific problems, and network with professionals in their field. Join us and become part of a dynamic ecosystem where academic excellence meets industry innovation.</p>
                            <Link to="/contact">
                                <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                            </Link>
                        </div>
                        <div className='col-lg-6 d-flex justify-content-center'>
                            <img src={AboutUsSectionImg} className='img-fluid w-75' alt="" />
                        </div>
                    </div>
                </div>

                <div className="container-lg">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-10 bg-light p-5 my-5">
                            <h3 className="text-center">Our mission</h3>
                            <div>
                                <p>At Armelubawa College of Engineering, our mission is to provide a dynamic and inclusive learning environment where students can thrive academically, intellectually, and professionally. We are committed to:
                                </p>

                            </div>
                            <div class="list-group">
                                <button type="button" class="list-group-item list-group-item-action">Providing rigorous and innovative engineering education that prepares students for successful careers and lifelong learning.</button>
                                <button type="button" class="list-group-item list-group-item-action">Conducting cutting-edge research that addresses real-world challenges and drives advancements in engineering and technology.</button>
                                <button type="button" class="list-group-item list-group-item-action">Fostering a culture of diversity, equity, and inclusion that celebrates the unique perspectives and contributions of all members of our community.</button>
                                <button type="button" class="list-group-item list-group-item-action">Cultivating a spirit of innovation, creativity, and entrepreneurship that empowers students to make meaningful contributions to society.</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container-lg">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-8 bg-light p-5 my-5">
                            <h3 className="text-center">Our Programs</h3>
                            <div>
                                <p>Armelubawa College of Engineering offers a comprehensive range of undergraduate and graduate programs in various engineering disciplines, including:</p>
                            </div>
                            <ul class="list-group list-group-flush my-5">
                                <li class="list-group-item">Electrical Engineering</li>
                                <li class="list-group-item">Mechanical Engineering</li>
                                <li class="list-group-item">Computer Science</li>
                                <li class="list-group-item">Civil Engineering</li>
                                <li class="list-group-item">Computer Engineering</li>
                            </ul>
                            <p>Our programs are designed to provide students with a solid foundation in engineering fundamentals while also offering opportunities for specialization, hands-on learning, and research experience.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="container-md">
                    <div className="row align-items-center justify-content-center">
                        <div className='col-md-2'>
                            <a href="/history" className='btn btn-outline-danger btn-lg my-4'>Go to History</a>
                        </div>
                    </div>
                </div>


                <div className='bg-dark text-light py-5'>
                    <ChooseSection />
                </div>




                <div className='bg-body-tertiary py-5'>
                    <div className="container">
                        <h2 className='text-center mb-5'>Our Winners</h2>
                        <div className='row g-4'>
                            {persons.map((person) => (
                                <div key={person.id} className='col-md-4'>
                                    <img src={person.img} className='img-fluid' alt="" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>






            </div>
        </>
    )
}

export default About;