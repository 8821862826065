import React, { useState } from 'react';
import './Courses.css';
import { Card, useAccordionButton } from 'react-bootstrap';
import ArtCourseImg from '../../utils/images/art-course.jpg';
import BusinessCourseImg from '../../utils/images/business-course.jpg';
import ComputerScienceCourseImg from '../../utils/images/computer-science-course.jpg';
import EducationCourseImg from '../../utils/images/education-course.jpg';
import HealthcareCourseImg from '../../utils/images/healthcare-course.jpg';
import LawCourseImg from '../../utils/images/law-course.jpg';
import person from '../../utils/images/blog2-img.jpg'
import computer from '../../utils/images/computer-science-course.jpg'
import MusicCourseImg from '../../utils/images/music-course.jpg';
import SportCourseImg from '../../utils/images/sport-course.jpg';
import FaqAccordion from '../../components/FaqAccordion/FaqAccordion';

const courses = [
    {
        id: 1,
        img: [ArtCourseImg],
        title: 'Department of Electrical Engineering',
        description: "Welcome to the Department of Electrical Engineering at Armelubawa College of Engineering!"
    },
    {
        id: 2,
        img: [BusinessCourseImg],
        title: 'Mechanical Engineering',
        description: "The Department of Mechanical Engineering at Armelubawa College of Engineering offers a comprehensive range of programs!"
    },
    {
        id: 3,
        img: [ComputerScienceCourseImg],
        title: 'Department of Mechanical Engineering',
        description: "Armelubawa College of Engineering's Mechanical Engineering program cultivates innovative problem-solvers equipped to tackle diverse challenges in modern industries."
    },
    {
        id: 4,
        img: [EducationCourseImg],
        title: 'Department of Computer Science',
        description: "Armelubawa College of Engineering's Computer Science program fosters cutting-edge innovation and equips students with the skills to drive technological advancements in today's digital landscape."
    },
    {
        id: 5,
        img: [person],
        title: 'Civil Engineering',
        description: "Armelubawa College of Engineering's Civil Engineering program empowers students to design sustainable infrastructure and shape the built environment for future generations."
    },
    {
        id: 6,
        img: [computer],
        title: "Department of Computer Engineering",
        description: "Armelubawa College of Engineering's Computer Engineering program bridges hardware and software disciplines, preparing students to innovate in the rapidly evolving technology industry."
    },
];

function Courses() {
    const [buttonExist, setButonExist] = useState(true);
    return (
        <div className='courses-page'>
            <header className='h-100 min-vh-100 d-flex align-items-center text-light'>
                <div className='container d-flex flex-column align-items-center'>
                    <div className='d-flex flex-column flex-sm-column align-items-center'>
                        <p className='fw-semibold'>Our Departments</p>
                        <p className='fw-semibold'>
                            Exploring Boundless Horizons, Empowering Minds, and Shaping Tomorrow's Innovators.
                        </p>
                        {/* <Link to="/courses">
                                <button type='button' className='btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Our Courses</button>
                            </Link>
                            <Link to="/contact">
                                <button type='button' className='btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0'>Contact Us</button>
                            </Link> */}
                    </div>
                </div>
            </header>

            <div className='container py-5'>
                <div className='row g-4 justify-content-center'>
                    {courses.map((course) => (
                        <div key={course.id} className='col-lg-6'>
                            <Card className='text-white shadow scale-hover-effect'>
                                <Card.Img src={course.img} />
                                <Card.ImgOverlay className='d-flex flex-column align-items-center justify-content-center p-md-5'>
                                    <Card.Title className='fs-1 text-danger text-center'>{course.title}</Card.Title>
                                    <Card.Text className='text-center'>{course.description}</Card.Text>
                                    {course.id < 3 && <Card.Link href={course.id===1?"/electrical":"/mechanical"} className='btn btn-danger mt-3'>Read More</Card.Link>}
                                </Card.ImgOverlay>
                            </Card>
                        </div>
                    ))}
                </div>
            </div>

            {/* <div className='bg-dark text-light py-5'>
            <FaqAccordion />
        </div> */}
        </div>
    )
}

export default Courses;